<template>
  <AppHeader />
  <Suspense>
    <template #default>
      <AppSearchs :search_id="search_id" />
    </template>
    <template #fallback>
      <AppLoading />
    </template>
  </Suspense>
  <AppFooter />
</template>

<script>
import AppHeader from "@/components/layouts/AppHeader";
// import AppSearchs from "@/components/Pages/AppSearchs";
import AppFooter from "@/components/layouts/AppFooter";
import AppLoading from "@/components/Spinners/AppLoading.vue";
import { defineAsyncComponent, Suspense } from "vue";
const AppSearchs = defineAsyncComponent(() =>
  import('@/components/Pages/AppSearchs')
)
export default {
  name: "Searchs",
  props: {
    search_id: String,
  },
  components: {
    AppHeader,
    AppSearchs,
    AppFooter,
    AppLoading,
    Suspense,
  },
};
</script>

<style>
</style>